import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import Calendar from "../calendar/Calendar";
import { clientConnect } from "../../client";
import { Fragment } from "react";

export class SectionCalendar extends Component {
  constructor(props) {
    super();
    this.state = {
      events: [],
      loadingEvents: true,
    };
  }

  componentDidMount() {
    this.populateEventsData("en-GB", this.props.sectionContent.contentSecondary, 3);
  }

  styleSection = (background) => {
    return {
      backgroundColor: background,
    };
  };

  rowStyles = () => {
    return {
      marginTop: "20px",
      marginBottom: "20px",
    };
  };

  render() {
    let url = "calendar--events";

    return (
      <div className={`col-lg-${this.props.sectionContent.widthInColumns} col-md-${this.props.sectionContent.widthInColumns} xs-text-center`}>
        {ReactHtmlParser(this.props.sectionContent.content)}

        <Calendar />

        {this.state.events.length > 0 && (
          <>
            <div className="row mt-5">
              <div className="col-md-12 text-center">
                <div className="h1">Upcoming events</div>
              </div>
            </div>

            {this.state.events.map((e, i) => (
              <>
                <div className="row">
                  <div className="col-md-11">
                    <a href={`/calendar--events/event/${e.url}`}>{e.title}</a>
                  </div>
                  <div className="col-md-1">
                    <a href={`/calendar--events/event/${e.url}`}>
                      <i className="fas fa-chevron-right"></i>
                    </a>
                  </div>
                </div>
                <hr />
              </>
            ))}
          </>
        )}
      </div>
    );
  }

  async populateEventsData(lng, url, itemsNum) {
    if (url) {
      this.setState({
        events: await clientConnect("api/event/categorylist", `${lng}/${url}/0/${itemsNum}`),
        loadingEvents: false,
      });
    } else {
      this.setState({
        events: await clientConnect("api/event/list", `${lng}/0/${itemsNum}`),
        loadingEvents: false,
      });
    }
  }
}

SectionCalendar.propTypes = {
  sectioncontents: PropTypes.object,
};

export default SectionCalendar;
