import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { clientConnect } from "../../client";

export class GalleryList extends Component {
  constructor(props) {
    super();
    this.state = {
      galleries: [],
      loadingGalleries: true,
    };
  }

  componentDidMount() {
    this.populateGalleriesData();
  }
  render() {
    return (
      <>
        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            paddingTop: "30px",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 xs-text-center">
                <div className="h1" style={{ textAlign: "center" }}>
                  {this.props.content.title}
                </div>
                <div className="title-page-divider">&nbsp;</div>
              </div>
            </div>

            {!this.state.loadingGalleries ? (
              <>
                {this.state.galleries.length > 0 ? (
                  this.state.galleries.map((gallery, i) => (
                    <div
                      style={{
                        borderBottom: "1px solid #051E38",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                      }}
                      key={i}
                    >
                      <div className="row">
                        {gallery.hasCoverPhoto ? (
                          <>
                            <div className="col-md-3">
                              <a href={`/${this.props.baseUrl.url}/gallery/${gallery.url}`}>
                                {gallery.mode === 2 && (
                                  <img
                                    src={process.env.REACT_APP_CDN1+`/galleryasset/get/${gallery.coverPhotoHash}`}
                                    alt={gallery.title}
                                    className="img-fluid"
                                    width={150}
                                    height={150}
                                  />
                                )}
                                {gallery.mode === 3 && (
                                  <img
                                    src={process.env.REACT_APP_CDN3+`/galleries/${gallery.hash}/thumbs/${gallery.coverPhotoUrl}`}
                                    alt={gallery.title}
                                    className="img-fluid"
                                    width={150}
                                    height={150}
                                  />
                                )}
                                {gallery.mode === 4 && (
                                  <img
                                    src={process.env.REACT_APP_CDN4+`/galleries/${gallery.hash}/thumbs/${gallery.coverPhotoUrl}`}
                                    alt={gallery.title}
                                    className="img-fluid"
                                    width={150}
                                    height={150}
                                  />
                                )}
                              </a>
                            </div>
                            <div className="col-md-9">
                              <h3>
                                <a href={`/${this.props.baseUrl.url}/gallery/${gallery.url}`}>{gallery.title}</a>
                              </h3>
                              <p> Photos in gallery: {gallery.photoCount}</p>
                              <p>
                                <a href={`/${this.props.baseUrl.url}/gallery/${gallery.url}`} className="btn btn-secondary">
                                  see the gallery...
                                </a>
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-md-12">
                              <h3>
                                <a href={`/${this.props.baseUrl.url}/gallery/${gallery.url}`}>{gallery.title}</a>
                              </h3>
                              <p>
                                <a href={`/${this.props.baseUrl.url}/gallery/${gallery.url}`} className="btn btn-secondary">
                                  see the gallery...
                                </a>
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <p className="text-center" style={{ fontSize: "52px" }}>{`{...}`}</p>
                    <p className="text-center">no galleries available</p>
                  </>
                )}
              </>
            ) : (
              <p className="text-center">Loading...</p>
            )}
          </div>
        </div>
      </>
    );
  }

  async populateGalleriesData() {
    this.setState({
      galleries: await clientConnect("api/gallery/list", ""),
      loadingGalleries: false,
    });
  }
}

GalleryList.propTypes = {
  content: PropTypes.object,
};

export default GalleryList;
