import React from "react";
import { css } from "@emotion/react";
// First way to import
import { ScaleLoader } from "react-spinners";
// Another way to import. This is recommended to reduce bundle size

const override = css`
  display: block;
  margin: 20px auto;
  border-color: #4bb9a8;
`;

class LoadingIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="sweet-loading text-center"
        style={{ marginTop: 50, marginBottom: 50 }}
      >
        <ScaleLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={"#d4213d"}
          loading={this.props.loading}
        />
        <h4 style={{ color: "#d4213d" }} className="text-center">
          Please wait, we are processing your request...
        </h4>
      </div>
    );
  }
}

export default LoadingIndicator;
