import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import BottomContent from "./BottomContent";
import BottomMenu from "./BottomMenu";
import BottomNewsletter from "./BottomNewsletter";

export class Bottom extends Component {
  styleSection = (margintop, marginbottom) => {
    return {
      marginTop: `${margintop}px`,
      marginBottom: `${marginbottom}px`,
    };
  };

  render() {
    return (
      <div className="bottomSection" style={this.styleSection(this.props.bottoms.marginTop, this.props.bottoms.marginBottom)}>
        <div className="container">
          <div className="row">
            {this.props.bottoms.bottomContents.map((bottomContent) =>
              (() => {
                switch (bottomContent.type) {
                  case "text":
                    return <BottomContent bottomContent={bottomContent} key={bottomContent.hash} />;
                    break;
                  case "newsletter":
                    return <BottomNewsletter bottomContent={bottomContent} key={bottomContent.hash} />;
                    break;
                  case "menu":
                    return <BottomMenu bottomContent={bottomContent} key={bottomContent.hash} />;
                    break;
                  default:
                    return <BottomContent bottomContent={bottomContent} key={bottomContent.hash} />;
                    break;
                }
              })()
            )}
          </div>
        </div>
      </div>
    );
  }
}

Bottom.propTypes = {
  bottomcontent: PropTypes.array,
};

export default Bottom;
