import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import GalleryDetails from "../gallery/GalleryDetails";
import GalleryList from "../gallery/GalleryList";

export class ContentGallery extends Component {
  render() {
    return (
      <div className="container">
        <div className="row" style={{ marginBottom: "30px" }}>
          <div className="col-lg-12 col-md-12 text-page-content">
            {(() => {
              switch (this.props.baseUrl.mode) {
                case "gallery":
                  return <GalleryDetails gallery={this.props.baseUrl.itemurl} baseUrl={this.props.baseUrl} content={this.props.content} />;
                default:
                  return <GalleryList baseUrl={this.props.baseUrl} content={this.props.content} title={this.props.title} />;
              }
            })()}
          </div>
        </div>
      </div>
    );
  }
}

ContentGallery.propTypes = {
  content: PropTypes.object,
};

export default ContentGallery;
