import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { clientConnect, clientConnectCustom } from "../../client";
import Loading from "../shared/Loading";
import { apiUrl2 } from "../../config";
import Container from "reactstrap/lib/Container";

export class MembersDetails extends Component {
  static displayName = MembersDetails.name;

  constructor(props) {
    super();
    this.state = {
      member: [],
      loadingMember: true,
      news: [],
      loadingNews: true,
    };
  }

  componentDidMount() {
    this.populateMemberData(this.props.member).then(() => {
      //console.log(this.state.member.tag.substring(1));
      this.populateMemberNewsData("en-GB", this.state.member.tag.substring(1));
    });
  }

  renderMember = (member, news) => {
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12">
            {member.hasLogo ? (
              <p className="text-center mt-2 mb-2">
                <img src={`${apiUrl2}api/catalog/getlogo/${member.url}`} alt={member.name} className="img-fluid" />
              </p>
            ) : (
              <p className="text-center mt-4 mb-4">
                <i className="far fa-building fa-3x"></i>
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <h2 className="mt-2">{member.name}</h2>
            <h4 className="mt-3">{member.industry}</h4>
            {member.isGolden === true && (
              <p className="mt-3">
                <span className="member-golden">Golden partner</span>
              </p>
            )}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 member-text">{ReactHtmlParser(member.description)}</div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <h3 className="member-tag">{member.tag}</h3>
          </div>
        </div>

        <div className="row justify-content-md-center mt-4">
          <div className="col-md-10">
            <h4 className="text-center">Countries of BEPOLUX activity</h4>
            <hr />
            <p className="text-center">
              {member.countryList.map((country, i, arr) => (
                <img src={process.env.PUBLIC_URL + "/images/" + country.url + ".png"} alt={country.value} className={arr.length - 1 !== i && "mr-3"} />
              ))}
            </p>
            <h4 className="text-center mt-4">Contact</h4>
            <hr />
            <div className="row">
              <div className="col-md-6 member-text">
                {member.contactPerson !== null && (
                  <p>
                    <strong>{member.contactPerson}</strong>
                  </p>
                )}
                <p>
                  {member.address}
                  <br />
                  {member.zip} {member.city}
                  <br />
                  {member.country}
                </p>
              </div>
              <div className="col-md-6 member-text">
                {member.email !== null && (
                  <div className="row">
                    <div className="col-1 member-text">
                      <i className="fas fa-envelope-open fa-fw"></i>
                    </div>
                    <div className="col-10 cut-text member-text">
                      <a href={`mailto:${member.email}`} target="_blank" rel="noopener noreferrer">
                        {member.email}
                      </a>
                    </div>
                  </div>
                )}
                {member.www !== null && (
                  <div className="row mt-1">
                    <div className="col-1 member-text">
                      <i className="fas fa-globe fa-fw"></i>
                    </div>
                    <div className="col-10 cut-text member-text">
                      <a href={member.www} target="_blank" rel="noopener noreferrer">
                        {member.www.replace(/(^\w+:|^)\/\//, "")}
                      </a>
                    </div>
                  </div>
                )}
                {member.facebook !== null && (
                  <div className="row mt-1">
                    <div className="col-1 member-text">
                      <i className="fab fa-facebook fa-fw"></i>
                    </div>
                    <div className="col-10 cut-text member-text">
                      <a href={member.facebook} target="_blank" rel="noopener noreferrer">
                        {member.facebook.replace(/(^\w+:|^)\/\//, "")}
                      </a>
                    </div>
                  </div>
                )}
                {member.twitter !== null && (
                  <div className="row mt-1">
                    <div className="col-1">
                      <i className="fab fa-twitter fa-fw"></i>
                    </div>
                    <div className="col-10 cut-text member-text">
                      <a href={member.twitter} target="_blank" rel="noopener noreferrer">
                        {member.twitter.replace(/(^\w+:|^)\/\//, "")}
                      </a>
                    </div>
                  </div>
                )}
                {member.linkedin !== null && (
                  <div className="row mt-1">
                    <div className="col-1">
                      <i className="fab fa-linkedin fa-fw"></i>
                    </div>
                    <div className="col-10 cut-text member-text">
                      <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                        {member.linkedin.replace(/(^\w+:|^)\/\//, "")}
                      </a>
                    </div>
                  </div>
                )}
                {member.youTube !== null && (
                  <div className="row mt-1">
                    <div className="col-1">
                      <i className="fab fa-youtube fa-fw"></i>
                    </div>
                    <div className="col-10 cut-text member-text">
                      <a href={member.youTube} target="_blank" rel="noopener noreferrer">
                        {member.youTube.replace(/(^\w+:|^)\/\//, "")}
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {member.articleList !== null && member.articleList.length > 0 && (
              <Fragment>
                <div className="row justify-content-md-center">
                  <div className="col-md-12">
                    <h4 className="text-center mt-4">Articles</h4>
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8"></div>
                </div>
              </Fragment>
            )}
            {news !== undefined && news.length > 0 && (
              <Fragment>
                <div className="row justify-content-md-center">
                  <div className="col-md-12">
                    <h4 className="text-center mt-4">News</h4>
                    <hr />
                  </div>
                </div>
                {news.map((n, i) => (
                  <div className="row justify-content-md-center" key={i}>
                    <div className="col-md-12" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                      <div className="row">
                        <div className="col-10 col-md-11">
                          <h5>
                            <a href={`/news/news/${n.url}`}>{n.title}</a>
                          </h5>
                        </div>
                        <div className="col-2 col-md-1">
                          <i className="fa fa-chevron-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Fragment>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <a href={`/${this.props.baseUrl.url}`} className="btn btn-secondary">
              Back to memebers list
            </a>
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    let member = this.state.loadingMember ? (
      <p>Loading...</p>
    ) : this.state.member !== undefined ? (
      this.renderMember(this.state.member, this.state.news)
    ) : (
      <h4 style={{ textAlign: "center", margin: "25px 0", fontWeight: "normal" }}>
        {`{...}`} <br />
        Member not found
      </h4>
    );

    return (
      <Container>
        <div class="col-lg-12 col-md-12 text-page-content" style={{ marginTop: "30px" }}>
          {this.props.content.title !== null && (
            <React.Fragment>
              <h1 className="text-page-header">{this.props.content.title}</h1>
              <div className="title-page-divider"></div>
            </React.Fragment>
          )}

          {member}

          {this.props.content.contentShowdate == true && (
            <p class="small">
              Data aktualizacji:
              {new Intl.DateTimeFormat("pl-PL", {
                year: "numeric",
                month: "long",
                day: "2-digit",
              }).format(this.props.content.contentDate)}
            </p>
          )}
        </div>
      </Container>
    );
  }

  async populateMemberData(url) {
    this.setState({
      member: await clientConnectCustom("api/catalog/get", url),
      loadingMember: false,
    });
  }

  async populateMemberNewsData(lng, tag) {
    this.setState({
      news: await clientConnect("api/news/search", `${lng}/${tag}`),
      loadingNews: false,
    });
  }
}

MembersDetails.propTypes = {
  content: PropTypes.object,
};

export default MembersDetails;
