import React, { Component } from "react";
//import ContentText from './ContentText'
import ContentLink from "./ContentLink";
import ContentNews from "./ContentNews";
import ContentCalendar from "./ContentCalendar";
import ContentGallery from "./ContentGallery";
import ContentVideoGallery from "./ContentVideoGallery";
import ContentMembers from "./ContentMembers";
import ContentContact from "./ContentContact";
import Section from "../section/Section";
import PropTypes from "prop-types";
import "./content.css";
import Bottom from "../shared/Bottom";
import { Container } from "reactstrap";
import { Helmet } from "react-helmet";

import { clientConnect } from "../../client";
import { Fragment } from "react";

import ContentLoader from "react-content-loader";

export class Content extends Component {
  static displayName = Content.name;

  constructor(props) {
    super();
    this.state = {
      page: [],
      loading: true,
    };
  }

  componentDidMount() {
    const { url } = this.props.match.params;
    this.populatePageData(url);

    //document.getElementById("root").style.backgroundImage = `url(${process.env.PUBLIC_URL + '/images/bcgkNormal.jpg'})`;
    //document.getElementById("root").style.backgroundPosition = 'top center';
    //document.getElementById("root").style.backgroundRepeat = 'no-repeat';
  }

  static renderHeader(page) {
    let headerUrl = "";
    if (page.hasHeader && page.imageAssetMode === 1) {
      headerUrl = process.env.REACT_APP_CDN1+`/imageasset/get/${page.imageAssetHash}`;
    }
    if (page.hasHeader && page.imageAssetMode === 3) {
      headerUrl = process.env.REACT_APP_CDN3+`/headers/${this.props.siteData.hash}/${page.imageAssetFileName}`;
    }
    if (page.hasHeader && page.imageAssetMode === 4) {
      headerUrl = process.env.REACT_APP_CDN4+`/headers/${this.props.siteData.hash}/${page.imageAssetFileName}`;
    }

    return (
      <>
        {page.hasHeader && (
          <div
            className="row-fluid"
            style={{
              backgroundImage: `url(${headerUrl})`,
              backgroundPosition: "top center",
              backgroundSize: "cover",
              height: "240px",
            }}
          >
            {page.titleShow && (
              <div className="container">
                <div className="row" style={{ marginBottom: "30px" }}>
                  <div className="col-lg-12 col-md-12">
                    <div className="header-title-box">
                      {page.module === "news" && <div className="h1">{page.title}</div>}
                      {page.module === "contact" && <h1>{page.title} </h1>}
                      {page.module === "video-gallery" && <h1>{page.title} </h1>}
                      {page.module === "gallery" && <h1>{page.title} </h1>}
                      {page.module === "calendar" && (page.mode !== "" ? <div className="h1">{page.title}</div> : <h1>{page.title} </h1>)}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }

  static renderPage(page, url) {
    //console.log(page.module);
    return (
      <React.Fragment>
        {/* {page.hasHeader && (
          <div
            className="row-fluid"
            style={{
              backgroundImage: `url(https://cdn.bluebirdcms.net/imageasset/get/${page.imageAssetHash})`,
              backgroungPosition: "top center no-repeat",
              height: "240px",
            }}
          ></div>
        )} */}
        <Fragment>
          {page.sectionOrder === true &&
            url.mode === undefined &&
            page.sections.map((sections) => <Section key={sections.hash} sections={sections} id={sections.hash} />)}

          {page.module === "link" && <ContentLink content={page.content} baseUrl={url} />}

          {page.module === "news" && <ContentNews content={page} baseUrl={url} />}

          {page.module === "calendar" && <ContentCalendar content={page} baseUrl={url} />}

          {page.module === "gallery" && <ContentGallery content={page} baseUrl={url} />}

          {page.module === "video-gallery" && <ContentVideoGallery content={page} baseUrl={url} />}

          {page.module === "bepolux-custom-members" && <ContentMembers content={page} baseUrl={url} />}

          {page.module === "contact" && <ContentContact content={page} baseUrl={url} />}

          {page.sectionOrder === false &&
            url.mode === undefined &&
            page.sections.map((sections) => <Section key={sections.hash} sections={sections} id={sections.hash} />)}
        </Fragment>
      </React.Fragment>
    );
  }

  static renderBottom(page) {
    return (
      <React.Fragment>
        <Bottom bottoms={page.bottoms} />
      </React.Fragment>
    );
  }

  static renderMetatags(page) {
    return (
      <Helmet>
        <title>{page.metaTitle}</title>
        <meta name="description" content={page.metaDescription} />
        <meta name="keywords" content={page.metaKeywords} />
        <meta name="robots" content={page.metaRobots} />
        <meta name="theme-color" content="#1f1f1f" />
        {/* <meta property="og:url" content="https://www.bepolux.org/" /> */}
        <meta property="og:type" content="image/png" />
        <meta property="og:title" content={page.metaTitle} />
        <meta property="og:description" content={page.metaDesc} />
        <meta property="og:image" content="https://www.bepolux.org/images/logo.png" />
      </Helmet>
    );
  }

  render() {
    let header = this.state.loading ? (
      <div className="row-fluid">
        <ContentLoader viewBox="0 0 400 160" height={240} width={"100%"} backgroundColor="transparent">
          <circle cx="150" cy="86" r="8" />
          <circle cx="194" cy="86" r="8" />
          <circle cx="238" cy="86" r="8" />
        </ContentLoader>
      </div>
    ) : (
      Content.renderHeader(this.state.page)
    );

    let content = this.state.loading ? <p className="text-center">Loading...</p> : Content.renderPage(this.state.page, this.props.match.params);

    let bottoms = this.state.loading ? <p className="text-center">Loading...</p> : Content.renderBottom(this.state.page);

    let metatags = this.state.loading ? "" : Content.renderMetatags(this.state.page);

    return (
      <React.Fragment>
        {metatags}

        {header}

        {content}

        {bottoms}
      </React.Fragment>
    );
  }

  async populatePageData(url) {
    this.setState({
      page: await clientConnect("api/page/get", url),
      loading: false,
    });
  }
}
