import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import NewsletterForm from "../newsletter/NewsletterForm";

export class BottomNewsletter extends Component {
  render() {
    return (
      <>
        <div className={`col-lg-${this.props.bottomContent.widthInColumns} col-md-${this.props.bottomContent.widthInColumns} xs-text-center`}>
          {ReactHtmlParser(this.props.bottomContent.content)}
          <NewsletterForm formType="inline" />
        </div>
      </>
    );
  }
}

BottomNewsletter.propTypes = {
  bottomcontent: PropTypes.object,
};

export default BottomNewsletter;
