const dev = process.env.NODE_ENV !== "production";

export const accessToken = "...";
export const siteId = "43ca4c5a-1abe-4797-8051-d301bc99d138";
//export const apiUrl = "https://localhost:44321/";
//export const apiUrl2 = "https://localhost:44351/";
//export const apiUrl = "https://api.bluebirdcms.net/";
//export const apiUrl2 = "https://bepolux-api.bluebirdcms.net/";

export const apiUrl2 = dev ? "http://localhost:7074/" : "https://bepolux-api-apim.bluebirdcms.net/";
export const apiUrl = dev ? "https://localhost:44321/" : "https://api.bluebirdcms.net/";
