import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { clientConnect } from "../../client";
import Loading from "../shared/Loading";

import { SRLWrapper } from "simple-react-lightbox";

export class GalleryDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      gallery: [],
      loadingGallery: true,
    };
  }

  componentDidMount() {
    this.populateGalleryData(this.props.gallery);
  }

  renderGallery = (gallery, url, hash, mode, type) => {
    let inRow = 6;
    let Tabs = gallery.length / inRow;

    let galleryList = new Array();

    function renderCols(gallery, tab, cols) {
      let start = tab * cols;
      let end = start + cols;

      if (end > gallery.length) {
        end = gallery.length;
      }

      var colsList = new Array();

      for (let j = start; j < end; j++) {
        colsList.push(
          <div className="col-md-2" key={`${tab}.${j}`}>
            <p className="text-center">
              {mode === 2 && (
                <a href={process.env.REACT_APP_CDN1+`/galleryasset/get/${gallery[j].hash}`}>
                  <img src={process.env.REACT_APP_CDN1+`/galleryasset/thumb/${gallery[j].hash}`} alt={gallery[j].name} className="img-fluid" />
                </a>
              )}
              {mode === 3 && (
                <a href={process.env.REACT_APP_CDN3+`galleries/${hash}/${gallery[j].url}`}>
                  <img src={process.env.REACT_APP_CDN3+`/galleries/${hash}/thumbs/${gallery[j].url}`} alt={gallery[j].name} className="img-fluid" />
                </a>
              )}
              {mode === 4 && (
                <a href={process.env.REACT_APP_CDN4+`/galleries/${hash}/${gallery[j].url}`}>
                  <img src={process.env.REACT_APP_CDN4+`/galleries/${hash}/thumbs/${gallery[j].url}`} alt={gallery[j].name} className="img-fluid" />
                </a>
              )}
            </p>
          </div>
        );
      }

      return colsList;
    }

    for (let i = 0; i < Tabs; i++) {
      let columnMultiply = 1;

      galleryList.push(
        <div className="row" key={i}>
          {renderCols(gallery, i, inRow)}
        </div>
      );
    }

    if (gallery.length > 0) {
      return galleryList;
    } else {
      return (
        <h4
          style={{
            textAlign: "center",
            margin: "25px 0",
            fontWeight: "normal",
          }}
        >
          {`{...}`} <br />
          No photos in gallery
        </h4>
      );
    }
  };

  render() {
    let galleryList = this.state.loadingGallery ? (
      <Loading loaded={this.state.loadingGallery} />
    ) : this.state.gallery !== undefined ? (
      this.renderGallery(this.state.gallery.photos, this.props.baseUrl.url, this.state.gallery.hash, this.state.gallery.mode, this.state.gallery.type)
    ) : (
      <h4 style={{ textAlign: "center", margin: "25px 0", fontWeight: "normal" }}>
        {`{...}`} <br />
        Gallery not found
      </h4>
    );

    return (
      <div className="col-lg-12 col-md-12 text-page-content" style={{ marginTop: "30px" }}>
        <h1 className="text-page-header">{this.state.gallery.title}</h1>
        <div className="title-page-divider"></div>

        {ReactHtmlParser(this.state.gallery.description)}

        <SRLWrapper>{galleryList}</SRLWrapper>
        {this.props.content.contentShowdate === true && (
          <p className="small">
            Last update:
            {new Intl.DateTimeFormat("pl-PL", {
              year: "numeric",
              month: "long",
              day: "2-digit",
            }).format(this.props.content.contentDate)}
          </p>
        )}
      </div>
    );
  }

  async populateGalleryData(url) {
    this.setState({ gallery: await clientConnect("api/gallery/get", url), loadingGallery: false });
  }
}

GalleryDetails.propTypes = {
  content: PropTypes.object,
};

export default GalleryDetails;
