export default {
  dialog: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    zIndex: '100000',
    backgroundColor: '#FFFFE0',
    padding: '10px',
  },
  container: {
    maxWidth: '960px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
  },
  message: {
    minHeight: '32px',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '130%',
    padding: '10px 0',
    color: '#000000',
  },
  policy: {
    fontSize: '12px',
    color: '#203872',
    textDecoration: 'underline',
  },
  selectPane: {
    display: 'table-cell',
    padding: '3px 0px 10px 0px',
  },
  optionWrapper: {
    display: 'inline-block',
    position: 'relative',
    marginRight: '7px',
    marginLeft: '"0px',
    float: 'left',
  },
  optionLabel: {
    height: 'auto',
    width: 'auto',
    minHeight: '14px',
    fontSize: '12px',
    color: '#333',
    display: 'inline-block',
    padding: '1px 0 0 20px',
    position: 'relative',
    top: '0',
    left: '0',
    zIndex: '1',
    cursor: 'default',
    verticalAlign: 'top',
    },
  listTitleLabel: {
    height: 'auto',
    width: 'auto',
    minHeight: '14px',
    fontSize: '12px',
    color: '#333',
    display: 'inline-block',
    padding: '1px 0 0 0',
    position: 'relative',
    top: '0',
    left: '0',
    zIndex: '1',
    cursor: 'default',
    verticalAlign: 'top',
  },
  checkbox: {
    position: 'absolute',
    top: '4px',
    left: '0',
    width: '14px',
    height: '14px',
    zIndex: '2',
    cursor: 'pointer',
  },
  buttonWrapper: {
    float: 'right',
  },
  button: {
    display: 'inline-block',
    backgroundColor: '#203872',
    padding: '7px 15px',
    minWidth: '80px',
    color: '#ffffff',
    textDecoration: 'none',
    fontSize: '14px',
    fontWeight: '400',
    marginRight: '5px',
    marginLeft: '5px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    border: 'none',
  },
};
