import React, { Component } from "react";
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
//const ScrollLink = Scroll.ScrollLink

import { clientConnect } from "../../client";

let mainColor = "#ffffff";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super();

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      menus: [],
      loading: true,
      menuOpen: props.menuOpen,
      color: "transparent",
    };
  }

  listenScrollEvent = (e) => {
    if (window.scrollY > 100) {
      this.setState({ color: mainColor });
    } else {
      this.setState({ color: "transparent" });
    }
  };

  sendData = () => {
    this.props.navMenuCallback(this.state.menuOpen);
  };

  componentDidMount() {
    this.populateMenuData();
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  // routeChange=()=> {
  //let path = `newPath`;
  //let history = useHistory();
  //history.push(path);
  //}

  static renderMenu(menus) {
    return (
      <React.Fragment>
        {menus.length > 0 && (
          <ul className="navbar-nav flex-grow">
            {menus.menuList[0].map((menuItem, i) => (
              <NavItem key={i}>
                {menuItem.miModule.includes("section") ? (
                  <React.Fragment>
                    {this.props.path === "/" ? (
                      <ScrollLink to={menuItem.url} spy={true} smooth={true} duration={500} offset={-180} className="text-light nav-link nav-link-coursor">
                        {menuItem.title}
                      </ScrollLink>
                    ) : (
                      <NavLink href={`/#${menuItem.url}`} className="text-light">
                        {menuItem.title}
                      </NavLink>
                    )}
                  </React.Fragment>
                ) : (
                  <NavLink tag={Link} className="text-light" to={menuItem.url}>
                    {menuItem.title}
                  </NavLink>
                )}
              </NavItem>
            ))}
          </ul>
        )}
      </React.Fragment>
    );
  }

  toggleMenu() {
    if (this.state.menuOpen !== this.props.menuOpen) {
      this.setState((state) => ({ menuOpen: !this.props.menuOpen }), this.sendData);
    } else {
      this.setState((state) => ({ menuOpen: !state.menuOpen }), this.sendData);
    }
  }

  render() {
    let mainMenu = this.state.loading ? <div></div> : NavMenu.renderMenu(this.state.menus);

    return (
      <header>
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm ng-white mb-3"
          dark
          fixed="top"
          style={{
            backgroundColor: global.window.scrollY > 100 ? mainColor : "transparent",
          }}
        >
          <Container>
            <NavbarBrand href="/">
              <img src={process.env.PUBLIC_URL + "/images/logo_transp.png"} alt="BEPOLUX" />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex" isOpen={!this.state.collapsed} navbar>
              {mainMenu}
            </Collapse>
            <div className="col-md-3 col-sm-3 text-center">
              <a className="nav-link text-blue" href="/members/signup">
                <i className="fa fa-user text-grey"></i>&nbsp;&nbsp;Become a member
              </a>
            </div>
            <div className="col-md-2 col-sm-2 hidden-xs">
              <div className="social-buttons">
                <a href="https://www.facebook.com/bepolux" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.linkedin.com/company/bepolux-the-belgian-luxembourg-polish-chamber-of-commerce" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-linkedin"></i>
                </a>
              </div>
            </div>
            <div className="col-md-1 col-sm-1">
              <button onClick={() => this.toggleMenu()} className="menu-button">
                <i className="fa fa-bars"></i>
              </button>
            </div>
          </Container>
        </Navbar>
      </header>
    );
  }

  async populateMenuData() {
    /*const response = await fetch('api/menu/main');
        const data = await response.json();
        this.setState({ menus: data, loading: false });*/
    this.setState({
      menus: await clientConnect("api/menu/get", null),
      loading: false,
    });
  }
}
